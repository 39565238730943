<template>
  <div class="Userhl_fapiao Userhl_rightbox">
    <el-table :data="tableData" border style="width: 100%" stripe>
      <el-table-column prop="rise" label="发票抬头" style=""> </el-table-column>
      <el-table-column prop="money" label="开票金额" style="">
      </el-table-column>
      <el-table-column prop="create_time" label="开票时间"></el-table-column>
      <el-table-column prop="" label="开票状态">
        <template slot-scope="scope">
          <span v-if="tableData[scope.$index].type == 1">待审核</span>
          <span v-if="tableData[scope.$index].type == 2">待邮寄</span>
          <span v-if="tableData[scope.$index].type == 3">已完成</span>
        </template>
      </el-table-column>
      <el-table-column prop="cz" label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="aaa(scope.$index)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :pager-count="5"
      @current-change="currentchange"
      :page-size="pagesize"
      background
      layout="prev, pager, next"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      title="发票信息"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="fapiao_pro">
        <p>
          <span>发票抬头</span>
          {{ fpall.rise }}
        </p>
        <p>
          <span>社会统一信用编码（税号）</span>
          {{ fpall.unified_social_credit_code }}
        </p>
        <p>
          <span>发票信息</span>
          {{ fpall.invoice_info }}
        </p>
        <p>
          <span>发票金额</span>
          ￥{{ fpall.money }}
        </p>
        <p>
          <span>发票时间</span>
          {{ fpall.create_time }}
        </p>
        <p>
          <span>开票状态</span>
          {{
            fpall.type == 1 ? "待审核" : fpall.type == 2 ? "待邮寄" : "已完成"
          }}
        </p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      tableData: [],
      pagesize: 10,
      total: 0,
      dialogVisible: false,
      fpall: {},
      userinfo: {},
    };
  },
  methods: {
    aaa(indexid) {
      var that = this;
      var fpid = that.tableData[indexid].id;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url:"/api/getUserInvoiceInfo",
        method: "post",
        data: {
          id: fpid,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.fpall = res.data.result;
          this.dialogVisible = true;
        }
      });
    },
    axiosqq(page) {
      var that = this;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url:"/api/getUserInvoice",
        method: "post",
        data: {
          page,
          limit: that.pagesize,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.total = res.data.result.count;
          that.tableData = res.data.result.list;
        }
      });
    },
    currentchange(index){
      this.axiosqq(index);
    }
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    this.axiosqq(1);
  },
};
</script>
